<template>
  <div class="custom-wrapper">
    <DxResponsiveBox :screen-by-width="screenByWidth" single-column-screen="xs" id="responsive-box" width="100%">
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />

      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />

      <!--Filters-->
      <DxItem class="category-list-selector">
        <DxLocation :row="0" :col="0" :colspan="4" screen="xs sm md lg" />
        <template #default >
          <div class="filterContainer">

            <!-- # STORE FILTER -->
            <div class="storeSelectorContainer">
              <!--                          temp-->
              <DxSelectBox style="font-family: 'Nunito'"
                           :dataSource="getOutletsList.filter(item => item.id != 0)"
                           displayExpr= "name"
                           :show-clear-button="false"
                           :placeholder="$store.state.selectedOutlet.name"
                           value-expr="id"
                           :onValueChanged="onOutletSelected"
                           :disabled="!pageDataReady"
                           styling-mode="underlined"
              />
              <!--                     v-model="selectedOutlet"-->

            </div>

            <div class="filtersContainer">

              <div>
                <!--Calendar-->
                <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? (new Date($store.state.selectedDate.slice(0,4), ($store.state.selectedDate.slice(4,6) - 1), $store.state.selectedDate.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.selectedDate.slice(0,4) : (new Date(String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4), (String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)), String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + String($store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4) }}</div>
                <div :class="['calendarContainer', calendarHidden ? 'calendarHidden' : '']">
                  <div class="closeCalendarBtn" @click="onShowCalendar">x</div>

                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button v-for="(dateBtn, ix) in [{name: 'month', value: 'year'}, {name: 'week', value: 'month'}]" :key="ix"
                            :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                            @click="onCalendarZoomLevelSelection(dateBtn)"
                            :disabled="dateBtn.value === 'month'"
                    >{{ dateBtn.name }}</button>
                  </div>

                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">{{ LOC.FILTERS.CALENDARREFTITLE }}</div>
                    <DxCalendar
                        @value-changed="onSelectedDate"
                        :zoom-level="calendarZoomLevel"
                        :max-zoom-level="calendarZoomLevel"
                        :max="maxSelectableDate"
                        :min="minSelectableDate"
                        cell-template="customCalendarCellTemplate"
                        :value="$store.state.selectedDate ? `${$store.state.selectedDate.slice(0,4)}-${$store.state.selectedDate.slice(4,6)}-01` : `${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)}-${('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(4,6)}-01`"
                    >
                      <template #customCalendarCellTemplate="{ data: data, index }">
                        <CustomCalendarCellTemplate :data="data" :cell-index="index" />
                      </template>
                    </DxCalendar>
                  </div>
                </div>

                <!--Settings-->
                <div class="display-settings-btn" @click="onShowSettings"><i class="dx-icon dx-icon-preferences fa-4x"></i></div>
                <div :class="['calendarContainer', settingsHidden ? 'calendarHidden' : '']">
                  <div class="closeCalendarBtn" @click="onShowSettings">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button v-for="(dateBtn, ix) in [{name: 'year', value: 'year'}]" :key="ix"
                            :class="[(dateBtn.name + '-btn'), calendarZoomLevel === dateBtn.value ? 'activeDate' : '', dateBtn.value === 'month' ? 'disabled-btn' : '']"
                            @click="onCalendarZoomLevelSelection(dateBtn)"
                            :disabled="dateBtn.value === 'year'"
                    >
                      {{ dateBtn.name }}
                    </button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">{{ LOC.FILTERS.CALENDARCOMPARISONTITLE }}</div>
                    <DxCalendar
                        @value-changed="onSelectedSetting"
                        :zoom-level="settingsZoomLevel"
                        :max-zoom-level="settingsZoomLevel"
                        :min-zoom-level="settingsZoomLevel"
                        :max="settingsMaxSelectableDate"
                        :min="settingsMinSelectableDate"
                        :value="$store.state.selectedSettings ? `${$store.state.selectedSettings.slice(0,4)}` : `${ '' + (('' + $store.state.newOutletLastSalesDates.lastAvailableSalesDate).slice(0,4)-1) }`"
                    >
                    </DxCalendar>
                  </div>
                </div>
              </div>


              <!-- # PRODUCT CATEGORIES FILTER -->
              <div class="categoriesListSelectorContainer" style="width: 350px; font-family: 'Nunito'">
                <DxSelectBox style="font-family: 'Nunito'"
                             :dataSource="getCategoriesList"
                             displayExpr= "description"
                             :placeholder="LOC.FILTERS.PRODCATPLACEHOLDER"
                             value-expr="id"
                             :onValueChanged="onProductCategorySelected"
                             :show-clear-button="true"
                             styling-mode="underlined"
                             :value="$store.state.selectedCategory"
                             :disabled="!pageDataReady || productCategoryFilterDisabledByPage"

                />
              </div>
            </div>
          </div>
        </template>
      </DxItem>

      <!-- 1st row -->
      <!-- 1st column -->
      <DxItem class="item text">
        <DxLocation :row="1" :col="0" :colspan="2" screen="xs sm md lg" />
        <template #default >
          <basic-chart-visualizer :component-title="LOC.OCCUPANCY.OCCUPANCY" :comparisonDate="comparisonDateTextGauge" :refDate="refDateTextGauge" :legendItemColor1="getOutletStyle.secondaryColor" :legendItemColor2="getOutletStyle.primaryColor">
            <template v-slot:componentDoughnut>
              <DxBarGauge
                  @drawn="onDrawn"
                  class="small-gauge"
                  :start-value="0"
                  :end-value="100"
                  :values="barGaugeER.dataRef"
                  :geometry="barGaugeGeometry"
                  :palette="[getOutletStyle.secondaryColor, getOutletStyle.primaryColor]"
                  resolveLabelOverlapping="hide"
                  :label="barGaugesOccupancyLabelsSettings"
              >
              </DxBarGauge>
            </template>
          </basic-chart-visualizer>
        </template>
      </DxItem>

      <!-- 2nd column -->
      <DxItem class="item text">
        <DxLocation :row="1" :col="2" :colspan="2" screen="xs sm md lg" />
        <template #default >
          <basic-chart-visualizer :component-title="LOC.OCCUPANCY.UNITS" :comparisonDate="comparisonDateOccupancy" :refDate="refDateOccupancy" :legendItemColor1="getOutletStyle.primaryColor" :legendItemColor2="getOutletStyle.secondaryColor">
            <template v-slot:componentDoughnut>
              <DxChart class="stackedbar" @drawn="onDrawn" id="chart" :data-source="fullstackedbar.dataSource">

                <DxArgumentAxis position="bottom" />

                <DxCommonSeriesSettings

                :label="{
                  font: {
                    color: '#000000'
                  },
                }"

                argument-field="units" type="stackedbar" :barWidth="35" vertical-alignment="top" />

                <DxSeries value-field="leasedUnits" :color="getOutletStyle.primaryColor" :name="comparisonDateText" stack="units">
                  <DxLabel :visible="true" background-color="rgba(255,255,255,0)"/>
                </DxSeries>

                <DxSeries value-field="vacantUnits" :color="getOutletStyle.secondaryColor" :name="refDateText" stack="units">
                  <DxLabel :visible="true" background-color="rgba(255,255,255,0)"/>
                </DxSeries>

                <DxSeries value-field="total" :color="colorTrasparent" name="" :label="{visible:true, horizontalOffset: 35, verticalOffset: 30}" type="scatter"/>

                <DxLegend :visible="false"/>

                <DxExport :enabled="false"/>

                <DxTooltip :enabled="true"/>

              </DxChart>
            </template>
          </basic-chart-visualizer>
        </template>
      </DxItem>

      <!--   2nd row   -->
      <DxItem>
        <DxLocation :row="2" :col="0" :colspan="4" screen="xs sm md lg" />
        <template #default >

          <stacked-chart-datagrid-visualizer
              :componentTitle="LOC.OCCUPANCY.BARCHARTDATAGRIDLEASETITLE"
              refDate=""
              :dataReady="effortRateDataReady"
              :notes="('(*) TBO Rolling counted in ' + barChartLeaseData[0].year)"
              :legendNotes="'Tap on a legend item to show its labels'"
          >
            <!--            :switch-table-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHTABLE'"-->
            <!--            :switch-chart-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHCHART'"-->
            <template v-slot:componentChart>
              <DxChart @drawn="onDrawn"
                       id="scatterChart-filtered-by-client"
                       :data-source="barChartLeaseData"
                       class="scatterChart leaseChart"
                       :commonAxisSettings="scatterChartCommonAxisSettings"
                       :loadingIndicator="{enabled: true}"
                       @initialized="saveLeaseChartInstance"
                       @point-click="onLeaseClick"
                       :customize-point="customizeLeaseBarColor"
                       @legend-click="legendItemSelected"
              >

                <!-- <DxCommonSeriesSettings type="bar" /> -->
                <DxSeries argument-field="plain" value-field="potentialRent" :color="getOutletStyle.primaryColor" type="bar" axis="potentialRent" :name="LOC.OCCUPANCY.CHARTLEASEVALUEAXIS1TITLE">
                  <DxLabel
                      :visible="leaseChartSelectedLegendItem == 'potentialRent'? true : false"
                      background-color="white"
                      :border="{ visible: true, color: getOutletStyle.primaryColor, width: 1 }"
                      :font="{color: '#333333'}"
                      :connector="{visible: true}"

                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxLabel>
                  <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                </DxSeries>

                <DxSeries argument-field="plain" value-field="gla" :color="getOutletStyle.secondaryColor" type="line" axis="gla" :name="LOC.OCCUPANCY.CHARTLEASEVALUEAXIS3TITLE">
                  <DxLabel
                      :visible="leaseChartSelectedLegendItem == 'gla'? true : false"
                      background-color="white"
                      :border="{ visible: true, color: getOutletStyle.secondaryColor, width: 1 }"
                      :font="{color: '#333333'}"
                      :connector="{visible: true}"
                  >
                    <DxFormat
                        type="fixedPoint"
                        :precision="0"
                    />
                  </DxLabel>

                  <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                </DxSeries>

                <DxSeries argument-field="plain" value-field="leasesCount" :color="getOutletStyle.tertiaryColor" type="line" axis="leasesCount" :name="LOC.OCCUPANCY.CHARTLEASEVALUEAXIS2TITLE">
                  <DxLabel
                      :visible="leaseChartSelectedLegendItem == 'leasesCount'? true : false"
                      :background-color="'white'"
                      :border="{ visible: true, color: getOutletStyle.tertiaryColor, width: 1 }"
                      :font="{color: '#333333'}"
                      :connector="{visible: true}"
                  />
                  <DxPoint :color="getOutletStyle.tertiaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                </DxSeries>

                <!--              <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">-->
                <!--                <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />-->
                <!--              </DxSeries>-->
                <!--              <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">-->
                <!--                <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />-->
                <!--              </DxSeries>-->
                <!--              <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipVisitors.argumentFormat" :format="scatterERLTM.tooltipVisitors.valueFormat" />-->

                <DxArgumentAxis :tick-interval="1"
                >
<!--                                :title="{ text: LOC.OCCUPANCY.CHARTLEASEARGUMENTAXISTITLE, margin: 20 }"-->
                  <DxGrid :visible="false" />
                  <DxMinorGrid :visible="false" />
                </DxArgumentAxis>
                <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                <!--                      todo: togliere primo e ultimo asse di argument grid -->
                <DxValueAxis
                    :grid="{ visible: true }"
                    :title="{ text: LOC.OCCUPANCY.CHARTLEASEVALUEAXIS1TITLE, margin: 10 }"
                    :visible="true"
                    name="potentialRent"
                />

                <DxValueAxis
                    :grid="{ visible: true }"
                    :title="{ text: LOC.OCCUPANCY.CHARTLEASEVALUEAXIS3TITLE, margin: 5 }"
                    :visible="true"
                    position="right"
                    name="gla"
                />

                <DxValueAxis
                    :grid="{ visible: true }"
                    :title="{ text: LOC.OCCUPANCY.CHARTLEASEVALUEAXIS2TITLE, margin: 5 }"
                    :visible="true"
                    position="right"
                    name="leasesCount"
                    :multipleAxesSpacing="20"
                />
                <DxLegend
                    :visible="true"
                    vertical-alignment="bottom"
                    horizontal-alignment="center"
                    :margin="{ top: 25 }"
                    itemTextPosition="right"
                    :font="getLegendFontSettings"
                    :customizeText="leaseChartLegendCustomText"
                />
                <DxCommonPaneSettings>
                  <DxBorder :visible="false" />
                </DxCommonPaneSettings>

                <!--              <template #tooltipTemplateFootfall="{ data }">-->
                <!--                <div style="text-align: center">-->
                <!--                  Amount: € {{ data.valueText }}-->
                <!--                </div>-->
                <!--              </template>-->

              </DxChart>
            </template>

<!--            <template v-slot:componentTab v-if="selectedBarChartLease == 'VACANT_UNITS'">-->
<!--              <div class="selected-bar-title" v-if="selectedBarChartLease != null">{{ selectedBarChartLeaseTitle }}</div>-->
<!--              <DxDataGrid-->
<!--                  id="dataGrid-filtered-by-client"-->
<!--                  class="datagrid"-->
<!--                  :data-source="datagridLeaseData.find(el => el.year == selectedBarChartLease ).items"-->
<!--                  key-expr="unit"-->
<!--                  :show-borders="false"-->
<!--                  :loadPanel="{enabled: true}"-->
<!--                  @exporting="onExportingDatagridEr"-->
<!--                  :show-column-lines="false"-->
<!--                  :show-row-lines="true"-->
<!--                  :column-auto-width="true"-->
<!--                  :column-hiding-enabled="true"-->
<!--                  :noDataText="LOC.COMMON.NODATA"-->
<!--              >-->
<!--                <DxPaging :enabled="false" />-->
<!--                <DxExport-->
<!--                    :enabled="true"-->
<!--                />-->
<!--                &lt;!&ndash;              <DxColumn&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-field="tenant"&ndash;&gt;-->
<!--                &lt;!&ndash;                  :caption="loc.EFFORTRATE.DATAGRIDCOL1CAPTION"&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-type="string"&ndash;&gt;-->
<!--                &lt;!&ndash;                  alignment="left"&ndash;&gt;-->
<!--                &lt;!&ndash;                  css-class="cell-custom-style cell-body-head"&ndash;&gt;-->
<!--                &lt;!&ndash;                  cell-template="diff-cell-template"&ndash;&gt;-->
<!--                &lt;!&ndash;              />&ndash;&gt;-->
<!--                &lt;!&ndash;              <DxColumn&ndash;&gt;-->
<!--                &lt;!&ndash;                  :allow-sorting="false"&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-field="tenantId"&ndash;&gt;-->
<!--                &lt;!&ndash;                  caption=""&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-type="string"&ndash;&gt;-->
<!--                &lt;!&ndash;                  alignment="left"&ndash;&gt;-->
<!--                &lt;!&ndash;                  css-class="cell-custom-style"&ndash;&gt;-->
<!--                &lt;!&ndash;                  cell-template="link-cell-template"&ndash;&gt;-->
<!--                &lt;!&ndash;              />&ndash;&gt;-->
<!--                <DxColumn-->
<!--                    data-field="unit"-->
<!--                    :caption="LOC.OCCUPANCY.DATAGRIDLEASEVACANTCOL0CAPTION"-->
<!--                    data-type="string"-->
<!--                    alignment="left"-->
<!--                    css-class="cell-custom-style"-->
<!--                />-->
<!--                <DxColumn-->
<!--                    css-class="cell-custom-style"-->
<!--                    data-field="gla"-->
<!--                    :caption="LOC.OCCUPANCY.DATAGRIDLEASEVACANTCOL1CAPTION"-->
<!--                    data-type="number"-->
<!--                    alignment="left"-->
<!--                    :customizeText="customizeDatagridDataDecimals"-->
<!--                >-->
<!--                  <DxFormat-->
<!--                      type="fixedPoint"-->
<!--                      :precision="2"-->
<!--                  />-->
<!--                </DxColumn>-->
<!--                &lt;!&ndash;              <DxColumn&ndash;&gt;-->
<!--                &lt;!&ndash;                  css-class="cell-custom-style"&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-field="er"&ndash;&gt;-->
<!--                &lt;!&ndash;                  :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"&ndash;&gt;-->
<!--                &lt;!&ndash;                  data-type="number"&ndash;&gt;-->
<!--                &lt;!&ndash;                  alignment="left"&ndash;&gt;-->
<!--                &lt;!&ndash;                  :customizeText="customizeDatagridDataPercentage"&ndash;&gt;-->
<!--                &lt;!&ndash;                  sort-order="asc"&ndash;&gt;-->
<!--                &lt;!&ndash;              >&ndash;&gt;-->
<!--                &lt;!&ndash;                <DxFormat&ndash;&gt;-->
<!--                &lt;!&ndash;                    type="fixedPoint"&ndash;&gt;-->
<!--                &lt;!&ndash;                    :precision="2"&ndash;&gt;-->
<!--                &lt;!&ndash;                />&ndash;&gt;-->
<!--                &lt;!&ndash;              </DxColumn>&ndash;&gt;-->

<!--                &lt;!&ndash; template personalizzato celle datagrid &ndash;&gt;-->
<!--                <template #diff-cell-template="{ data }">-->
<!--                  <DiffCell :cell-data="data"/>-->
<!--                </template>-->

<!--                <template #link-cell-template="{ data }">-->
<!--                  <LinkCellTemplate :data="data" />-->
<!--                </template>-->
<!--              </DxDataGrid>-->
<!--            </template>-->

            <template v-slot:componentTab v-if="selectedBarChartLease != null">
              <div class="selected-bar-title" v-if="selectedBarChartLease != null">{{ selectedBarChartLeaseTitle }}: {{ LOC.OCCUPANCY.DATAGRIDSUBTITLE }}</div>
              <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="datagridLeaseData.find(el => el.year == selectedBarChartLease).items"
                  key-expr="tenantId"
                  :show-borders="false"
                  :loadPanel="{enabled: true}"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="LOC.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport
                    :enabled="true"
                />
                <DxColumn
                    sort-order="asc"
                    data-field="tenant"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL0CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style cell-body-head"
                    cell-template="diff-cell-template"
                />
                <DxColumn
                    :allow-sorting="false"
                    data-field="tenantId"
                    caption=""
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="link-cell-template"
                />
                <DxColumn
                    data-field="company"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL1CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />
                <DxColumn
                    data-field="contractExpiryDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL2CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    data-field="tboExpiryDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL21CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    data-field="tboLatestNoticeDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL22CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    :allow-sorting="false"
                    data-field="tboRolling"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL23CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="rolling-cell-template"
                />
                <DxColumn
                    data-field="unit"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL3CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="gla"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL4CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="potentialRent"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL6CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="0"
                  />
                </DxColumn>

                <DxColumn
                    data-field="prodCat"
                    :caption="LOC.OCCUPANCY.DATAGRIDLEASECOL5CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />
                <!--                  <DxColumn-->
                <!--                      css-class="cell-custom-style"-->
                <!--                      data-field="er"-->
                <!--                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"-->
                <!--                      data-type="number"-->
                <!--                      alignment="left"-->
                <!--                      :customizeText="customizeDatagridDataPercentage"-->
                <!--                      sort-order="asc"-->
                <!--                  >-->
                <!--                    <DxFormat-->
                <!--                        type="fixedPoint"-->
                <!--                        :precision="2"-->
                <!--                    />-->
                <!--                  </DxColumn>-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data"/>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>

                <template #rolling-cell-template="{ data }">
                  <RollingCellTemplate :data="data" />
                </template>
              </DxDataGrid>
<!--              <div v-if="selectedBarChartLease == 'TBO_ROLLING'" style="color: #666666; padding-top: 5px">(*) TBO Rolling counted with respect to {{ datagridContractsToBreakOptData.tboBreakDate }}</div>-->
            </template>

          </stacked-chart-datagrid-visualizer>
        </template>

      </DxItem>

      <!--   3rd row   -->
      <DxItem>
        <DxLocation :row="3" :col="0" :colspan="4" screen="xs sm md lg" />
        <template #default >
          <stacked-chart-datagrid-visualizer
            :componentTitle="LOC.OCCUPANCY.BARCHARTDATAGRIDEXPIRYTITLE"
            :refDate="'Reference date: ' + datagridContractsToExpiryData.expiryDate"
            :dataReady="effortRateDataReady"
        >
<!--              :switch-table-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHTABLE'"-->
<!--              :switch-chart-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHCHART'"-->
            <template v-slot:componentChart>
              <DxChart @drawn="onDrawn"
                       id="scatterChart-filtered-by-client"
                       :data-source="barChartContractsToExpiryData"
                       class="scatterChart"
                       :commonAxisSettings="scatterChartCommonAxisSettings"
                       :loadingIndicator="{enabled: true}"
                       @initialized="saveExpiryInstance"
                       @point-click="onExpiryBarClick"
                       :customize-point="customizeExpiryBarColor"
              >

                <DxCommonSeriesSettings type="bar" />
                <DxSeries argument-field="plain" value-field="contractsCount" :color="getOutletStyle.primaryColor" >
                  <DxLabel
                      :visible="true"
                      background-color="white"
                      :border="{ visible: true, color: '#333333', width: 1 }"
                      :font="{color: '#666666'}"
                  />
                  <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                </DxSeries>
  <!--              <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">-->
  <!--                <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />-->
  <!--              </DxSeries>-->
  <!--              <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">-->
  <!--                <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />-->
  <!--              </DxSeries>-->
  <!--              <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipVisitors.argumentFormat" :format="scatterERLTM.tooltipVisitors.valueFormat" />-->

                <DxArgumentAxis :tick-interval="1"
                    :title="{ text: LOC.OCCUPANCY.CHARTEXPIRYARGUMENTAXISTITLE, margin: 20 }"
                >
                  <DxGrid :visible="false" />
                  <DxMinorGrid :visible="false" />
                </DxArgumentAxis>
                <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                <!--                      todo: togliere primo e ultimo asse di argument grid -->
                <DxValueAxis
                    :grid="{ visible: true }"
                    :title="{ text: LOC.OCCUPANCY.CHARTEXPIRYVALUEAXISTITLE, margin: 20 }"
                    :visible="true"
                />
                <DxLegend
                    :visible="false"
                    vertical-alignment="bottom"
                    horizontal-alignment="center"
                    :margin="{ top: 25 }"
                    itemTextPosition="right"
                    :font="getLegendFontSettings"
                />
                <DxCommonPaneSettings>
                  <DxBorder :visible="false" />
                </DxCommonPaneSettings>

  <!--              <template #tooltipTemplateFootfall="{ data }">-->
  <!--                <div style="text-align: center">-->
  <!--                  Amount: € {{ data.valueText }}-->
  <!--                </div>-->
  <!--              </template>-->

              </DxChart>
            </template>

            <template v-slot:componentTab v-if="selectedBarChartExpiry == 'VACANT_UNITS'">
              <div class="selected-bar-title" v-if="selectedBarChartExpiry != null">{{ selectedBarChartExpiryTitle }}</div>
              <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="datagridContractsToExpiryData.find(el => el.durationType == selectedBarChartExpiry).items"
                  key-expr="unit"
                  :show-borders="false"
                  :loadPanel="{enabled: true}"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="LOC.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport
                    :enabled="true"
                />
  <!--              <DxColumn-->
  <!--                  data-field="tenant"-->
  <!--                  :caption="loc.EFFORTRATE.DATAGRIDCOL1CAPTION"-->
  <!--                  data-type="string"-->
  <!--                  alignment="left"-->
  <!--                  css-class="cell-custom-style cell-body-head"-->
  <!--                  cell-template="diff-cell-template"-->
  <!--              />-->
  <!--              <DxColumn-->
  <!--                  :allow-sorting="false"-->
  <!--                  data-field="tenantId"-->
  <!--                  caption=""-->
  <!--                  data-type="string"-->
  <!--                  alignment="left"-->
  <!--                  css-class="cell-custom-style"-->
  <!--                  cell-template="link-cell-template"-->
  <!--              />-->
                <DxColumn
                    data-field="unit"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYVACANTCOL0CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    sort-order="asc"
                />
                <DxColumn
                    css-class="cell-custom-style"
                    data-field="gla"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYVACANTCOL1CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>
  <!--              <DxColumn-->
  <!--                  css-class="cell-custom-style"-->
  <!--                  data-field="er"-->
  <!--                  :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"-->
  <!--                  data-type="number"-->
  <!--                  alignment="left"-->
  <!--                  :customizeText="customizeDatagridDataPercentage"-->
  <!--                  sort-order="asc"-->
  <!--              >-->
  <!--                <DxFormat-->
  <!--                    type="fixedPoint"-->
  <!--                    :precision="2"-->
  <!--                />-->
  <!--              </DxColumn>-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data"/>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>
              </DxDataGrid>
            </template>

            <template v-slot:componentTab v-else-if="selectedBarChartExpiry != 'VACANT_UNITS' && selectedBarChartExpiry != null">
              <div class="selected-bar-title" v-if="selectedBarChartExpiry != null">{{ selectedBarChartExpiryTitle }}: {{ LOC.OCCUPANCY.DATAGRIDSUBTITLE }}</div>
              <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="datagridContractsToExpiryData.find(el => el.durationType == selectedBarChartExpiry).items"
                  key-expr="tenantId"
                  :show-borders="false"
                  :loadPanel="{enabled: true}"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="LOC.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport
                    :enabled="true"
                />
                <DxColumn
                    sort-order="asc"
                    data-field="tenant"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL0CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style cell-body-head"
                    cell-template="diff-cell-template"
                />
                <DxColumn
                    :allow-sorting="false"
                    data-field="tenantId"
                    caption=""
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="link-cell-template"
                />
                <DxColumn
                    data-field="company"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL1CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />
                <DxColumn
                    data-field="contractExpiryDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL2CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    data-field="unit"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL3CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="gla"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL4CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>

                <DxColumn
                    data-field="prodCat"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL5CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="er"
                    :caption="LOC.OCCUPANCY.DATAGRIDEXPIRYCOL6CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataPercentage"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>
                <!--                  <DxColumn-->
                <!--                      css-class="cell-custom-style"-->
                <!--                      data-field="er"-->
                <!--                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"-->
                <!--                      data-type="number"-->
                <!--                      alignment="left"-->
                <!--                      :customizeText="customizeDatagridDataPercentage"-->
                <!--                      sort-order="asc"-->
                <!--                  >-->
                <!--                    <DxFormat-->
                <!--                        type="fixedPoint"-->
                <!--                        :precision="2"-->
                <!--                    />-->
                <!--                  </DxColumn>-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data"/>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>
              </DxDataGrid>
            </template>

          </stacked-chart-datagrid-visualizer>
        </template>


      </DxItem>


      <!--   4th row   -->
      <DxItem>
        <DxLocation :row="4" :col="0" :colspan="4" screen="xs sm md lg" />
        <template #default >

          <stacked-chart-datagrid-visualizer
              :componentTitle="LOC.OCCUPANCY.BARCHARTDATAGRIDBREAKOPTTITLE"
              :dataReady="effortRateDataReady"
              :notes="('(*) TBO Rolling counted with respect to ' + datagridContractsToBreakOptData.tboBreakDate)"
              :refDate="'Reference date: ' + datagridContractsToExpiryData.expiryDate"
          >
            <!--            :switch-table-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHTABLE'"-->
            <!--            :switch-chart-btn="'loc.EFFORTRATE.CHARTDATAGRIDSWITCHCHART'"-->
            <template v-slot:componentChart>
              <DxChart @drawn="onDrawn"
                       id="scatterChart-filtered-by-client"
                       :data-source="barChartContractsToBreakOptData"
                       class="scatterChart"
                       :commonAxisSettings="scatterChartCommonAxisSettings"
                       :loadingIndicator="{enabled: true}"
                       @initialized="saveScatterErInstance1"
                       @point-click="onBreakOptBarClick"
                       :customize-point="customizeBreakOptionBarColor"
              >

                <DxCommonSeriesSettings type="bar" />
                <DxSeries argument-field="plain" value-field="contractsCount" :color="getOutletStyle.primaryColor" >
                  <DxLabel
                      :visible="true"
                      background-color="white"
                      :border="{ visible: true, color: '#333333', width: 1 }"
                      :font="{color: '#666666'}"
                  />
                  <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />
                </DxSeries>
                <!--              <DxSeries argument-field="plain" value-field="amountPY" :color="getOutletStyle.secondaryColor" :name="displayPreviousYear">-->
                <!--                <DxPoint :color="getOutletStyle.secondaryColor" :size="scatterERLTM.points2.size" :visible="true" />-->
                <!--              </DxSeries>-->
                <!--              <DxSeries argument-field="plain" value-field="amount" :color="getOutletStyle.primaryColor" :name="displayCurrentYear">-->
                <!--                <DxPoint :color="getOutletStyle.primaryColor" :size="scatterERLTM.points1.size" :visible="true" />-->
                <!--              </DxSeries>-->
                <!--              <DxTooltip :enabled="true" content-template="tooltipTemplateFootfall" :argument-format="scatterERLTM.tooltipVisitors.argumentFormat" :format="scatterERLTM.tooltipVisitors.valueFormat" />-->

                <DxArgumentAxis :tick-interval="1"
                                :title="{ text: LOC.OCCUPANCY.CHARTBREAKOPTARGUMENTAXISTITLE, margin: 20 }"
                >
                  <DxGrid :visible="false" />
                  <DxMinorGrid :visible="false" />
                </DxArgumentAxis>
                <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                <!--                      todo: togliere primo e ultimo asse di argument grid -->
                <DxValueAxis
                    :grid="{ visible: true }"
                    :title="{ text: LOC.OCCUPANCY.CHARTBREAKOPTVALUEAXISTITLE, margin: 20 }"
                    :visible="true"
                />
                <DxLegend
                    :visible="false"
                    vertical-alignment="bottom"
                    horizontal-alignment="center"
                    :margin="{ top: 25 }"
                    itemTextPosition="right"
                    :font="getLegendFontSettings"
                />
                <DxCommonPaneSettings>
                  <DxBorder :visible="false" />
                </DxCommonPaneSettings>

                <!--              <template #tooltipTemplateFootfall="{ data }">-->
                <!--                <div style="text-align: center">-->
                <!--                  Amount: € {{ data.valueText }}-->
                <!--                </div>-->
                <!--              </template>-->

              </DxChart>
            </template>

            <template v-slot:componentTab v-if="selectedBarChartBreakOpt == 'VACANT_UNITS'">
              <div class="selected-bar-title" v-if="selectedBarChartBreakOpt != null">{{ selectedBarChartBreakOptTitle }}</div>
              <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="datagridContractsToBreakOptData.find(el => el.durationType == selectedBarChartBreakOpt).items"
                  key-expr="unit"
                  :show-borders="false"
                  :loadPanel="{enabled: true}"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="LOC.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport
                    :enabled="true"
                />
                <!--              <DxColumn-->
                <!--                  data-field="tenant"-->
                <!--                  :caption="loc.EFFORTRATE.DATAGRIDCOL1CAPTION"-->
                <!--                  data-type="string"-->
                <!--                  alignment="left"-->
                <!--                  css-class="cell-custom-style cell-body-head"-->
                <!--                  cell-template="diff-cell-template"-->
                <!--              />-->
                <!--              <DxColumn-->
                <!--                  :allow-sorting="false"-->
                <!--                  data-field="tenantId"-->
                <!--                  caption=""-->
                <!--                  data-type="string"-->
                <!--                  alignment="left"-->
                <!--                  css-class="cell-custom-style"-->
                <!--                  cell-template="link-cell-template"-->
                <!--              />-->
                <DxColumn
                    data-field="unit"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTVACANTCOL0CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    sort-order="asc"
                />
                <DxColumn
                    css-class="cell-custom-style"
                    data-field="gla"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTVACANTCOL1CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>
                <!--              <DxColumn-->
                <!--                  css-class="cell-custom-style"-->
                <!--                  data-field="er"-->
                <!--                  :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"-->
                <!--                  data-type="number"-->
                <!--                  alignment="left"-->
                <!--                  :customizeText="customizeDatagridDataPercentage"-->
                <!--                  sort-order="asc"-->
                <!--              >-->
                <!--                <DxFormat-->
                <!--                    type="fixedPoint"-->
                <!--                    :precision="2"-->
                <!--                />-->
                <!--              </DxColumn>-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data"/>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>
              </DxDataGrid>
            </template>

            <template v-slot:componentTab v-else-if="selectedBarChartBreakOpt != 'VACANT_UNITS' && selectedBarChartBreakOpt != null">
              <div class="selected-bar-title" v-if="selectedBarChartBreakOpt != null">{{ selectedBarChartBreakOptTitle }}: {{ LOC.OCCUPANCY.DATAGRIDSUBTITLE }}</div>
              <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="datagridContractsToBreakOptData.find(el => el.durationType == selectedBarChartBreakOpt).items"
                  key-expr="tenantId"
                  :show-borders="false"
                  :loadPanel="{enabled: true}"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="LOC.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport
                    :enabled="true"
                />
                <DxColumn
                    sort-order="asc"
                    data-field="tenant"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL0CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style cell-body-head"
                    cell-template="diff-cell-template"
                />
                <DxColumn
                    :allow-sorting="false"
                    data-field="tenantId"
                    caption=""
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="link-cell-template"
                />
                <DxColumn
                    data-field="company"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL1CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />
                <DxColumn
                    data-field="contractExpiryDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL2CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    data-field="tboExpiryDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL21CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    data-field="tboLatestNoticeDate"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL22CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    :customizeText="customCellDateText"
                />
                <DxColumn
                    :allow-sorting="false"
                    data-field="tboRolling"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL23CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="rolling-cell-template"
                />
                <DxColumn
                    data-field="unit"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL3CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="gla"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL4CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>

                <DxColumn
                    data-field="prodCat"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL5CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                />

                <DxColumn
                    css-class="cell-custom-style"
                    data-field="er"
                    :caption="LOC.OCCUPANCY.DATAGRIDBREAKOPTCOL6CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataPercentage"
                >
                  <DxFormat
                      type="fixedPoint"
                      :precision="2"
                  />
                </DxColumn>
                <!--                  <DxColumn-->
                <!--                      css-class="cell-custom-style"-->
                <!--                      data-field="er"-->
                <!--                      :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"-->
                <!--                      data-type="number"-->
                <!--                      alignment="left"-->
                <!--                      :customizeText="customizeDatagridDataPercentage"-->
                <!--                      sort-order="asc"-->
                <!--                  >-->
                <!--                    <DxFormat-->
                <!--                        type="fixedPoint"-->
                <!--                        :precision="2"-->
                <!--                    />-->
                <!--                  </DxColumn>-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data"/>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>

                <template #rolling-cell-template="{ data }">
                  <RollingCellTemplate :data="data" />
                </template>
              </DxDataGrid>
<!--              <div v-if="selectedBarChartBreakOpt == 'TBO_ROLLING'" style="color: #666666; padding-top: 5px">(*) TBO Rolling counted with respect to {{ datagridContractsToBreakOptData.tboBreakDate }}</div>-->
            </template>

          </stacked-chart-datagrid-visualizer>
        </template>

      </DxItem>

      <!--   footer   -->
<!--      <DxItem>-->
<!--        <DxLocation :row="2" :col="2" :colspan="2" screen="xs sm md lg" />-->
<!--        <template #default>-->
<!--          <div class="app-version">v. {{ getStoredAppVersion.versionNumber }} - {{ getStoredAppVersion.versionDate }}</div>-->
<!--        </template>-->
<!--      </DxItem>-->

    </DxResponsiveBox>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from "devextreme/localization";

import DxSelectBox from 'devextreme-vue/select-box';
import 'devextreme/dist/css/dx.light.css';
import ApiCalls from "@/services/ApiCalls";

import { mapGetters } from "vuex";
import DxCalendar from 'devextreme-vue/calendar';

import { loc } from "@/config/languages/en.js";

import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from "devextreme-vue/responsive-box";

/******************************************************************************************************
 * # COMPONENTE - INIZIO
 **/

import { DxBarGauge } from 'devextreme-vue/bar-gauge';

import DxDataGrid, {
  // DxLoadPanel,
  DxPaging, DxColumn, DxFormat, DxExport } from 'devextreme-vue/data-grid';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import {
  DxChart,
  DxCommonSeriesSettings,
  DxSeries,
  DxLegend,
  DxTooltip,
  DxValueAxis,
  DxArgumentAxis,
  DxPoint,

  DxGrid,
  DxMinorGrid,
  DxCommonPaneSettings,
  DxBorder,

  // DxZoomAndPan,

  // DxSize,
} from "devextreme-vue/chart";

import {
  // DxFormat,
  DxLabel,
  // DxConnector,
  // DxExport
} from 'devextreme-vue/pie-chart';

//import DxButton from 'devextreme-vue/button';


import DiffCell from '@/components/datagrid/DiffCell.vue';

/**
 * # /COMPONENTE - FINE
 ******************************************************************************************************/


export default {
  components: {
    DxResponsiveBox,
    DxItem,
    DxLocation,
    DxCol,
    DxRow,
    DxSelectBox,
    //DxButton,
    DxCalendar,

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/
    DxDataGrid,
    // DxLoadPanel,
    DxPaging,
    // DxExport,


    DxChart,
    DxSeries,
    DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxValueAxis,
    DxArgumentAxis,
    DxGrid,
    DxMinorGrid,
    DxCommonPaneSettings,
    DxBorder,
    // DxSize,
    DxColumn,
    DxFormat,


    // DxPieChart,
    // DxFormat,
    DxLabel,
    // DxConnector,
    DxExport,

    DxBarGauge,
    //DxZoomAndPan,

    DiffCell, // componente per template personalizzato celle datagrid

    /**
     * # /COMPONENTE - FINE
     ******************************************************************************************************/
  },

  data() {
    /***************
     * # PROVVISORIO: ARRIVERà DA VUEX
     ***************/
        // let outlet = this.$store.getters.getSelectedOutlet.id ? this.$store.getters.getSelectedOutlet.id : 1; // #1
        // let refDate = {year: 2021, month: 10};
    let selectedProductCategory = 0;
    // console.log("er outlet from store: ", outlet)

    return {

      /***************
       * # PROVVISORIO: ARRIVERà DA VUEX
       ***************/
      // parametri per composizione chiamate - prov (arriveranno da vuex, impostato da subheader/partials/drop-down component)
      // outletId: outlet, /* #globale */
      // refDate: refDate, /* #globale */

      /** ## BAR GAUGE **********/
      barGaugesLabelsSettings: {indent: 10, format: this.format, customizeText: this.customizeText, font: this.barGaugeLabelFont},
      barGaugesOccupancyLabelsSettings: {indent: 10, format: { type: 'fixedPoint', precision: 2 }, customizeText: this.customizeText, font: this.barGaugeLabelFont},

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      // colore barra selezionata
      selectedBarColor: "#26B6C1",

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      effortRateDataReady: false, /************* # COMPONENTE *********************************/

      /** ## DATE PER I COMPONENTI **********/
      refDateText: "", // arriva da chiamata refDate.plain
      comparisonDateText: "", // arriva da chiamata comparisonDate.plain
      refDateTextGauge: "",
      comparisonDateTextGauge: "",

      comparisonDateOccupancy: "", // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento
      refDateOccupancy: "", // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento

      selectedProductCategory: selectedProductCategory, // #globale

      /** ## SELETTORE CATEGORIE MERCEOLOGICHE **********/
      categories: [], // todo: globale o componente? ogni quanto si ripopolerà? verrà preso da localstorage dopo prima chiamata?

      // DATAGRID CATEGORIES COMPONENT
      // selectedCategory: null, // forse non più utile... #check
      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

      /***************
       * # GENERALI E GLOBALI
       ***************/
      effortRateData: null, /************* # COMPONENTE *********************************/
      // dataFilteredByProductCategory: [], // #componente

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      pageDataReady: false, /************* # COMPONENTE *********************************/

      // flag per abilitare filtro categorie merceologiche (dipende da pagina - domain) #n
      productCategoryFilterDisabledByPage: null,

      /** ## LOCALIZZAZIONE **********/
      LOC: loc,

      locale: "it",

      /** ## BREAKPOINTS **********/
      screenByWidth: function (width) {
        if (width < 768) return "xs";
        if (width < 1024) return "sm";
        if (width < 1150) return "md";
        // if (width < 1150) return "md";
        return "lg";
      },


      //Settings
      settingsZoomLevel: "decade",
      settingsMaxSelectableDate: null,
      settingsMinSelectableDate: null,
      settingsHidden: true,

      /** ## CALENDAR **********/
      calendarZoomLevel: "year", /* #componente: impostata di default, dipende da pagina: usata per cambiare stato active */
      maxSelectableDate: null,
      minSelectableDate: null, // #n
      calendarHidden: true,


      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## BAR GAUGE **********/
      format: { /* #globale */
        type: 'fixedPoint',
        precision: 2
      },

      /** ## DATAGRID **********/
      customizeDatagridDataDecimals(value) { /* #globale */
        // console.log("custom data col: ", value)
        return value.valueText;
        // return value.valueText + " €";
      },

      customizeDatagridDataPercentage(value) { /* #globale */
        return value.valueText;
        // return value.valueText + " %";
      },

      // datagrid expiry custom date cell
      customCellDateText(value) {
        let rowDate = value.valueText;
        return rowDate == '' ? '' : `${rowDate.slice(6,8)}/${rowDate.slice(4,6)}/${rowDate.slice(0,4)}`; // #qui: da sostituire con funzione globale displayCorrectFormat
      },

      // datagrid loader chiamata filtrata via api: ancora utile?
      loadingVisible: true, /* #componente */

      // lease chart datagrid - series label: indica quale series label deve essere visualizzata (dipende da click su legenda)
      leaseChartSelectedLegendItem: "potentialRent", /* #componente */

      /***************
       * # COMPONENT ER LTM DISTRIBUTION - BAR GAUGE
       ***************/
      // todo: trasformare in oggetto ogni componente (solo var componente)?

      barGaugeER: { // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      fullstackedbar: {
        dataSource: [],
        leasedUnitsREF: 0,
        vacantUnitsREF: 0,
        totaleREF: 0,
        leasedUnitsREFPY: 0,
        vacantUnitsREFPY: 0,
        totaleREFPY: 0,
      },

      barGaugeGeometry: { /* #globale */
        endAngle:-30,
        startAngle:210
      },
      colorGood: "#59C47F", /* #globale */
      colorNormal: "#FFD341", /* #globale */
      colorBad: "#FF5633", /* #globale */
      colorBadLight: "#F8B5BF", /* #globale */
      colorTrasparent: "rgba(255,255,255,0)", /* #globale */
      barGaugeLabelFont: {family: 'Nunito, sans-serif', size: 18, weight: 400}, /* #globale */
      // todo: label font-size a 15 in 768 e 1024

      barChartContractsToExpiryData: null,
      datagridContractsToExpiryData: null,
      selectedBarChartExpiry: null,
      selectedBarChartExpiryTitle: null,

      barChartContractsToBreakOptData: null,
      datagridContractsToBreakOptData: null,
      selectedBarChartBreakOpt: null,
      selectedBarChartBreakOptTitle: null,

      barChartLeaseData: null,
      datagridLeaseData: null,
      selectedBarChartLease: null,
      selectedBarChartLeaseTitle: null,

      /***************
       * # COMPONENT TEXT VISITORS
       ***************/
      textVisitors: {
        dataFilteredByProductCategory: {},
      },


      /***************
       * # COMPONENT ER LTM
       ***************/
      scatterDatagridERLTM: {
        // componentTitle: "Effort Rate LTM %",
        dataFilteredByProductCategory: null,
      },

      /** ## SCATTER PLOT **********/
      effortRateDisplayMode: "chart", /* #componente */

      expiryInstance: null, /* #componente */
      leaseChartInstance: null, /* #componente */
      scatterErInstance1: null, /* #componente */
      scatterErInstance2: null, /* #componente */

      scatterChartCommonAxisSettings: { /* #globale */
        //todo: color non viene applicato: scoprire cosa lo sovrascrive
        label:
            { font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#787878' }
            },
        title:
            { margin: 14 ,
              font:
                  { size: 14, family: 'Nunito', weight: 400, color: '#666666' }
            }
      },

      scatterERLTM: {
        // argumentAxis: {
        //   title: { text: 'Net Sales (€)'},
        // },
        // valueAxis: {
        //   title: { text: 'E.R. (%)'},
        // },
        series1: {
          color: "#FF5633",
        },
        series2: {
          color: "#F8B5BF",
        },
        points1: {
          color: "#FF7373",
          size: 15,
        },
        points2: {
          color: "#F8B5BF",
          size: 15,
        },
        tooltipVisitors: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'fixedPoint' },
        },
        tooltipCr: {
          argumentFormat: { type: 'fixedPoint' },
          valueFormat: { type: 'decimal', precision: 2 },
        },
      },

      /***************
       * # COMPONENT WORST PERFORMER - DATAGRID
       ***************/
      datagridERWorstPerformer: {
        // componentTitle: "Worst Performers",
        dataFilteredByProductCategory: null,
      },

      /***************
       * # COMPONENT BEST PERFORMER - DATAGRID
       ***************/
      datagridERBestPerformer: {
        // componentTitle: "Best Performers",
        dataFilteredByProductCategory: null,
      },

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

    };
  },

  computed: {
    ...mapGetters(["getSelectedOutlet", "getOutletsList", "getCategoriesList", "getOutletLastSalesDates", "getNewOutletLastSalesDates", "getOutletStyle", "getStoredAppVersion", "getLegendFontSettings"]),

    /***************
     * # CALENDAR
     ***************/
    displayStringDate() { /* #globale */
      if(this.$store.state.selectedDate) {
        let year = this.$store.state.selectedDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.selectedDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      } else {
        let year = this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(Number((this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(3, 5) - 1)))

        return `${month} ${year}`;
      }
    },
  },

  methods: {

    /***************
     * # CALENDAR
     ***************/

    // funzione per convertire numeri da una a due cifre
    convertFormatDate(date) {
      console.log("date: ", date);
      return String(date).length < 2 ? ("0" + date) : date;
    },

    onSelectedDate(e) {
      // console.log("selectedDate: ", new Date(e.value));
      let temp = new Date(e.value);
      // console.log("temp: ", temp);
      let selectedDate = `${temp.getFullYear()}${this.convertFormatDate(Number(temp.getMonth())+1)}${this.convertFormatDate(temp.getDate())}`
      // console.log("selectedDate: ", selectedDate);

      // imposto data selezionata in vuex
      this.$store.dispatch("dispatchSelectedDate", selectedDate);
      console.log("selectedDate - vuex: ", this.$store.state.selectedDate);

      // chiamata api per riprendere dati pagina er (tenendo conto di categoria selezionata)
      this.getEffortRateData();

      // nascondi il calendario
      this.calendarHidden = true;

    },

    onSelectedSetting(e) {

      let temp = new Date(e.value);
      let selectedSetting = `${temp.getFullYear()}`;
      this.$store.dispatch("dispatchSelectedSetting", selectedSetting);

      this.getEffortRateData();
      this.settingsHidden = true;
    },

    // funzione per impostare il range di date selezionabili (month, week)
    onCalendarZoomLevelSelection(calendarZoomLevelSelected) {
      // impostazione zoom level calendario
      this.calendarZoomLevel = calendarZoomLevelSelected.value;
      console.log("calendar zoom level: ", this.calendarZoomLevel);

      // imposta ultima data selezionabile
      this.setMaxSelectableDate()
    },

    // imposta ultima data selezionabile
    setMaxSelectableDate() {
      if(this.calendarZoomLevel === "year") {
        // console.log("prova start date: ", this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate);
        let tempLastDateYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4);
        let tempLastDateMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempLastDate = new Date(tempLastDateYear, tempLastDateMonth, 0);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.maxSelectableDate = `${tempLastDate.getFullYear()}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n

        // console.log("calendar max selectable date: ", this.maxSelectableDate);
        // console.log("calendar max selectable date - old: ", this.$store.state.outletLastSalesDates.salesLastDayClosedMonth);  // old

        //  imposto min data selezionabile su calendario // #n
        console.log("prova start first date: ", this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate);
        let tempFirstDateYear = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(0, 4);
        let tempFirstDateMonth = (this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + "").slice(4, 6);

        // ricavo data di partenza in formato data
        let tempFirstDate = new Date(tempFirstDateYear, tempFirstDateMonth, 1);
        console.log("temp first date: ", tempFirstDate);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.minSelectableDate = `${tempFirstDate.getFullYear()}${(tempFirstDate.getMonth()).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempFirstDate.getDate().toLocaleString(undefined, {minimumIntegerDigits: 2})}`; // #n

        this.settingsMaxSelectableDate = `${tempLastDate.getFullYear()-1}${(tempLastDate.getMonth() + 1).toLocaleString(undefined, {minimumIntegerDigits: 2})}${tempLastDate.getDate()}`; // #n;
        this.settingsMinSelectableDate = "2018";
      }

      // if(this.calendarZoomLevel === "month") { // per ora non utilizzato !? attenzione: da aggiornare con lastAvailableSalesDate !? // #n
      //   this.maxSelectableDate = this.$store.state.outletLastSalesDates.salesLastDayClosedWeek;
      // }

      console.log("max selectable data: ", this.maxSelectableDate);
      console.log("calendar min selectable date: ", this.minSelectableDate);
    },

    onShowCalendar() {
      this.calendarHidden === true ? this.calendarHidden = false : this.calendarHidden = true;
    },

    onShowSettings() {
      this.settingsHidden === true ? this.settingsHidden = false : this.settingsHidden = true;
    },


    // getCellCssClass(date) {
    //   let cssClass = '';
    //   const holydays = [[1, 0], [4, 6], [25, 11]];
    //
    //   if (this.isWeekend(date)) { cssClass = 'weekend'; }
    //
    //   holydays.forEach((item) => {
    //     if (date.getDate() === item[0] && date.getMonth() === item[1]) {
    //       cssClass = 'holyday';
    //     }
    //   });
    //
    //   return cssClass;
    // },

    /***************
     * # DATAGRID CHART
     ***************/
    /** ## CONVERTE GLI ORDINALI IN MESI (FOOTFALL, ...) **********/
    fromOrdinalToMonth(ordinalNumber) { /* #globale */
      // console.log("fn fromOrdinalToMonth called");

      if(typeof ordinalNumber != "number") {
        ordinalNumber = Number(ordinalNumber)
      }

      let output = ordinalNumber;
      switch(ordinalNumber) {
        case 1:
          output = "Jan";
          break;
        case 2:
          output = "Feb";
          break;
        case 3:
          output = "Mar";
          break;
        case 4:
          output = "Apr";
          break;
        case 5:
          output = "May";
          break;
        case 6:
          output = "Jun";
          break;
        case 7:
          output = "Jul";
          break;
        case 8:
          output = "Aug";
          break;
        case 9:
          output = "Sep";
          break;
        case 10:
          output = "Oct";
          break;
        case 11:
          output = "Nov";
          break;
        case 12:
          output = "Dec";
          break;
        default:
          output = "-";
      }

      return output;
    },

    /** ## VALORE VISUALIZZATO IN ARGUMENT AXIS **********/
    newArgAxisValue(value) {
      return this.fromOrdinalToMonth(value.value);
    },

    /***************
     * # GENERALE
     ***************/
    onDrawn(e) { /* #globale */
      setTimeout(function () {
        e.component.render()
      }, 1)
    },


    /** ## FILTRO OUTLETS - parte 1: impostazione filtro e chiamate api **********/
    onOutletSelected(e) {
      console.log("selezionato outlet: ", e.value);

      // condizione per pulsante clear: onclick passa null -> defaultOutlet
      let selectedOutletId = e.value == null ? 1 : e.value;

      // ricavo l'outlet dall'id selezionato (in vuex selectedOutlet è l'obj completo)
      let selectedOutlet = this.$store.state.outletsList.filter(outlet => outlet.id == selectedOutletId)[0];

      // invio l'outlet selezionato a vuex
      this.$store.dispatch("dispatchSelectedOutlet", selectedOutlet)

      console.log("selected outlet - vuex: ", this.$store.state.selectedOutlet);

      // al cambio di outlet, reimposto le categorie a 0 (all): non tutti gli outlets hanno le stesse categorie
      this.$store.dispatch("dispatchSelectedCategory", 0);
      // reimpostare anche selectbox categorie
      console.log("on outlet change, reset selected category - vuex: ", this.$store.state.selectedCategory);

      // recupero le categorie dell'outlet e le imposto nel vuex
      this.getDataByCategories();

      // reimposto le ultime date selezionabili in calendario
      this.setMaxSelectableDate();
    },

    /** ## CHIAMATA API FILTRO OUTLETS (anche chiamata iniziale pagina) **********/
    getEffortRateData() {

      let outletId = this.$store.state.selectedOutlet.id;
      let outletsDatesYear = "";
      let outletsDatesMonth = "";
      let yearComp = "";

      if(this.$store.state.selectedDate == null) {

        outletsDatesYear = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(0, 4); // nel caso arrivi un numero, trasformo in stringa // #n
        outletsDatesMonth = (this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + "").slice(4, 6); // #n

      } else {

        outletsDatesYear = this.$store.state.selectedDate.slice(0, 4);
        outletsDatesMonth = this.$store.state.selectedDate.slice(4, 6);
      }

      if(this.$store.state.selectedSettings == null) {

        yearComp = outletsDatesYear-1;

      } else {

        yearComp = this.$store.state.selectedSettings;
      }

      ApiCalls.getOccupacy(outletId, outletsDatesYear, outletsDatesMonth, yearComp)
        .then(res => {

          let currentDomain = res.data.metadata.domain;
          this.$store.dispatch("dispatchCurrentDomain", currentDomain);

          if(this.$store.state.pagesDisabledFilters.productCategoryFilter.some(domain => domain == currentDomain)) {

            this.productCategoryFilterDisabledByPage = true;
            this.$store.dispatch("dispatchSelectedCategory", 0);

          } else {

            this.productCategoryFilterDisabledByPage = false;
          }

          this.effortRateData = res.data;

          this.setComponentsData();
        })
        .catch(err => console.log(err.response));
    },


    /** ## CHIAMATA LOCALE FILTRO CLIENT - CATEGORIE **********/
    onProductCategorySelected(e) {

      // imposto selectedCategory in vuex
      console.log("selezionato categoria: ", e.value);
      e.value == null ?
          this.$store.dispatch("dispatchSelectedCategory", 0):
          this.$store.dispatch("dispatchSelectedCategory", e.value);

      console.log("selected category - vuex: ", this.$store.state.selectedCategory);

      // rivalorizzo i dataset dei vari componenti
      this.setComponentsData();
    },

    /** ## VALORIZZAZIONE DATASET DEI VARI COMPONENTI **********/
    setComponentsData() {

      let result = this.effortRateData.result.occupancySnapshot;
      let metadata = this.effortRateData.metadata;
      let selectedProductCategory = this.$store.state.selectedCategory;
      let fullResult = this.effortRateData.result;

      //proprietà che verrà usata per filtrare i tenants a seconda delle categorie di prodotto: se categoria negativa, fitrerò su food / non food, altrimenti su categoria prodotto
      //let targetProperty = selectedProductCategory < 0 ? "prodCatClusterId" : "prodCatId";

      //DATA FOR TEXT VISITORS
      //this.textVisitors.dataFilteredByProductCategory = result.footfallTotals.filter(el => el[targetProperty] === selectedProductCategory)[0];

      //Dates
      metadata.periods[0] && metadata.periods[0].type === "REF" ? this.refDateText = metadata.periods[0].plain : this.refDateText = "not specified";
      metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ? this.comparisonDateText = metadata.periodsComparison[0].plain : this.comparisonDateText = "not specified";

      this.barGaugeER.dataRef = (result.occupancyREFPY && result.occupancyREF) ? [result.occupancyREFPY, result.occupancyREF] : [0,0];
      this.fullstackedbar.leasedUnitsREF = result.leasedUnitsREF ?? 0;
      this.fullstackedbar.vacantUnitsREF = result.vacantUnitsREF ?? 0;
      this.fullstackedbar.totaleREF = this.fullstackedbar.leasedUnitsREF + this.fullstackedbar.vacantUnitsREF;
      this.fullstackedbar.leasedUnitsREFPY = result.leasedUnitsREFPY ?? 0;
      this.fullstackedbar.vacantUnitsREFPY = result.vacantUnitsREFPY ?? 0;
      this.fullstackedbar.totaleREFPY = this.fullstackedbar.leasedUnitsREFPY + this.fullstackedbar.vacantUnitsREFPY;

      this.refDateTextGauge = this.refDateText + " (" + this.barGaugeER.dataRef[1].toLocaleString("it-IT") + "%)";
      this.comparisonDateTextGauge = this.comparisonDateText + " (" + this.barGaugeER.dataRef[0].toLocaleString("it-IT") + "%)";

      this.comparisonDateOccupancy = loc.OCCUPANCY.LEASED, // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento
      this.refDateOccupancy = loc.OCCUPANCY.VACANT, // necessario perché altrimenti il componente non riceve i dati in tempo e non compare al primo caricamento

      this.fullstackedbar.dataSource = [
      {
        units: this.comparisonDateText,
        leasedUnits: this.fullstackedbar.leasedUnitsREFPY,
        vacantUnits: this.fullstackedbar.vacantUnitsREFPY,
        total: this.fullstackedbar.totaleREFPY
      },
      {
        units: this.refDateText,
        leasedUnits: this.fullstackedbar.leasedUnitsREF,
        vacantUnits: this.fullstackedbar.vacantUnitsREF,
        total: this.fullstackedbar.totaleREF,
      }];

      console.debug("full result: ", fullResult)

      this.barChartContractsToExpiryData = [...fullResult.occupancyContractData.contracts2ExpiryCount];
      console.debug("barChartContractsToExpiryData: ", this.barChartContractsToExpiryData);
      this.datagridContractsToExpiryData = [...fullResult.occupancyContractData.contracts2ExpiryDetails];
      // this.datagridContractsToExpiryData = [...fullResult.occupancyContractData.contracts2ExpiryDetails[0].items];
      this.datagridContractsToExpiryData.expiryDate = this.displayCorrectFormat({ value: (metadata.periods.find(el => el.type == "REF").dateEnd), isDate: true });
      console.debug("datagridContractsToExpiryData: ", this.datagridContractsToExpiryData.expiryDate);

      this.barChartContractsToBreakOptData = [...fullResult.occupancyContractData.contracts2TBOCount];
      console.debug("barChartContractsToBreakOptData: ", this.barChartContractsToBreakOptData);
      this.datagridContractsToBreakOptData = [...fullResult.occupancyContractData.contracts2TBODetails];
      // this.datagridContractsToBreakOptData = [...fullResult.occupancyContractData.contracts2ExpiryDetails[0].items];
      console.debug("datagridContractsToBreakOptData: ", this.datagridContractsToBreakOptData);
      this.datagridContractsToBreakOptData.tboBreakDate = this.displayCorrectFormat({ value: (metadata.periods.find(el => el.type == "REF").dateEnd), isDate: true });

      this.barChartLeaseData = [...fullResult.occupancyLeaseExpiryScheduleData.leaseExpiryScheduleAggregate];
      console.debug("barChartLeaseData: ", this.barChartLeaseData);
      this.datagridLeaseData = [...fullResult.occupancyLeaseExpiryScheduleData.leaseExpiryScheduleDetails];
      // this.datagridContractsToBreakOptData = [...fullResult.occupancyContractData.contracts2ExpiryDetails[0].items];
      console.debug("datagridLeaseData: ", this.datagridLeaseData);
      this.datagridLeaseData.tboBreakDate = this.displayCorrectFormat({ value: (metadata.periods.find(el => el.type == "REF").dateEnd), isDate: true });

      console.log("selectedProductCategory: ", selectedProductCategory);

      //DATA FOR LINE CHART E DATAGRID
      //this.scatterDatagridERLTM.dataFilteredByProductCategory = result.occupancySnapshot.filter(el => el[targetProperty] === selectedProductCategory)[0].items;
      //console.log("scatterDatagridERLTM.dataFilteredByProductCategory all: ", this.scatterDatagridERLTM.dataFilteredByProductCategory);

      this.pageDataReady = true; /* #componente */
    },

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/

    // bar chart lease
    customizeLeaseBarColor(bar) {
       console.debug("tbo bar color: ", bar);

       if(bar.series.axis == "potentialRent") {

         if(bar.index == 0 && this.selectedBarChartLease != bar.data.year) {
           return { color: "#AA192F" };
         } else if(bar.index == 0 && this.selectedBarChartLease == bar.data.year) {
           return { color: this.selectedBarColor };
         } else if(bar.index != 0 && this.selectedBarChartLease == bar.data.year) {
           return { color: this.selectedBarColor };
         }

       }

      //
      // if(this.selectedBarChartBreakOpt == bar.data.durationType) {
      //     return { color: "#dda3ab" };
      // }

    },

    onLeaseClick(e) {
      e.target.select();
      let selection = e.target.data.year;
      selection == this.selectedBarChartLease ? this.selectedBarChartLease = null : this.selectedBarChartLease = selection;
      console.debug("bar chart point target: ", e.target.data.year);
      this.selectedBarChartLeaseTitle = e.target.data.plain;
    },

    leaseChartLegendCustomText(data) {
      console.debug("lease chart legend: ", data)
      return data.seriesName
    },

    // lease chart datagrid - series label: indica quale series label deve essere visualizzata (dipende da click su legenda)
    legendItemSelected(data) {
      // console.log("legendItemSelected: ", data)
      this.leaseChartSelectedLegendItem = data.target.axis;
    },

    // bar chart expiry
    onExpiryBarClick(e) {
      e.target.select();
      let selection = e.target.data.durationType;
      selection == this.selectedBarChartExpiry ? this.selectedBarChartExpiry = null : this.selectedBarChartExpiry = selection;
      console.debug("bar chart point target: ", e.target.data.durationType);
      this.selectedBarChartExpiryTitle = e.target.data.plain;
    },

    customizeExpiryBarColor(bar) {
      // console.debug("tbo bar color: ", bar);

      if(this.selectedBarChartExpiry == bar.data.durationType) {
          return { color: this.selectedBarColor};
          // return { border: { color: "blue", width: 1, visible: true } };
      }

    },

    // bar chart break option
    customizeBreakOptionBarColor(bar) {
      // console.debug("tbo bar color: ", bar);

      if(bar.data.durationType == "TBO_ROLLING" && this.selectedBarChartBreakOpt != bar.data.durationType) {
        return { color: "#AA192F" };
      } else if(bar.data.durationType == "TBO_ROLLING" && this.selectedBarChartBreakOpt == bar.data.durationType) {
        return { color: this.selectedBarColor };
      } else if(bar.data.durationType != "TBO_ROLLING" && this.selectedBarChartBreakOpt == bar.data.durationType) {
        return { color: this.selectedBarColor };
      }
      //
      // if(this.selectedBarChartBreakOpt == bar.data.durationType) {
      //     return { color: "#dda3ab" };
      // }

    },

    onBreakOptBarClick(e) {
      e.target.select();
      let selection = e.target.data.durationType;
      selection == this.selectedBarChartBreakOpt ? this.selectedBarChartBreakOpt = null : this.selectedBarChartBreakOpt = selection;
      console.debug("bar chart point target: ", e.target.data.durationType);
      this.selectedBarChartBreakOptTitle = e.target.data.plain;
    },


    /***************
     * # BAR GAUGE
     ***************/
    customizeText({valueText}) {
      return `${valueText} %`;
    },

    /***************
     * # DATAGRID
     ***************/
    /** ## EXPORT BUTTON **********/
    onExportingDatagridEr(e) { /* #globale */
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = {name: 'Arial', size: 12};
          excelCell.alignment = {horizontal: 'left'};
        }
      }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
              saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'DataGrid.xlsx');
            });
      });
      e.cancel = true;
    },

    /***************
     * # SCATTER
     ***************/
    saveExpiryInstance(e) {
      this.expiryInstance = e.component;
    },


    /** ## RESET ZOOM BUTTON **********/
    saveLeaseChartInstance(e) {
      this.leaseChartInstance = e.component;
    },

    saveScatterErInstance1(e) {
      this.scatterErInstance1 = e.component;
    },

    resetScatterErZoom1() {
      console.log(this.scatterErInstance1)
      this.scatterErInstance1.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    saveScatterErInstance2(e) {
      this.scatterErInstance2 = e.component;
    },

    resetScatterErZoom2() {
      console.log(this.scatterErInstance2)
      this.scatterErInstance2.resetVisualRange()
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    /** ## TOOLTIP **********/

    //  DATAGRID WORST EFFORT RATE
    //   sortDataWorstER(data) {
    //     return data.sort((a,b) => b.effortRatePercentLTM - a.effortRatePercentLTM);
    //   },
  },

  /**
   * # /COMPONENTE - FINE
   ******************************************************************************************************/

  beforeCreate() {
    // this.outletId = this.$store.getters.getSelectedOutlet.id; // #1
  },

  created() {
    loadMessages(itMessages);
    locale(this.locale);

    // #todo: adottare sintassi con params xé + chiara?

    // console.log("vuex: ", this.$store);

    /***************
     * # 0: main.js
     ***************/
    this.getOutletsData();


  },



};

// todo: chiedere perché componenti scoped prevengono alcune regole css (es: css-class in dxcolumn
</script>

<style lang="scss" scoped>

.selected-bar-title {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  color: #333333;
  padding-top: 25px;
}
/***************
* # FILTRI
***************/
//
//.filterContainer { /* #globale */
//  padding: 10px;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: row !important;
//  align-items: center;
//
//  //h2 {
//  //  font-family: Nunito, sans-serif;
//  //  font-size: 26px;
//  //  font-weight: 600;
//  //
//  //  @media screen and (max-width: 1024px){
//  //    font-size: 22px;
//  //  }
//  //}
//
//  //#categoriesListProv {
//  //  text-align: center;
//  //  padding: 7px;
//  //  border: none;
//  //  border-radius: 14px;
//  //  appearance: none;
//  //
//  //  color: #AA192F;
//  //  background-color: #FDE5E8;
//  //  //width: 117px;
//  //  //height: 24px;
//  //}
//
//}

/** ## CALENDAR **********/


/***************
* # BAR GAUGE
***************/
.gauge {
  height: 300px;
  //height: 232px;
}

.stackedbar, .small-gauge {
  //height: 232px;
  height: 260px;
}

/***************
* # SCATTER - DATAGRID
***************/
.scatterChart, .datagrid {
  height: 413px;
}


/***************
* # COMPONENT WORST PERFORMER - DATAGRID
***************/

/*** ***** #!! ATTENZIONE: alcuni elementi css non vengono presi xé il componente è scoped
 -> passarli in custom-style.css !! */

//.tenant-column {
//  color: orange !important;
//  font-size: 50px !important;
//  background-color: green !important;
//}
//
//
//.cell-highlighted {
//  background-color: green !important;
//  color: orange !important;
//  font-style: italic !important;
//  font-size: 25px !important;
//}


// boh? #check
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
// /boh? #check

@media screen and (max-width: 1024px) {
  .gauge { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 240px;
    //height: 175px;
  }

  .stackedbar, .small-gauge {
    //height: 175px;
    height: 200px;
  }

  .scatterChart, .datagrid { // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 313px;
  }
}

@media screen and (max-width: 768px) {

  .stackedbar, .small-gauge {
    height: 180px;
  }

}
</style>
