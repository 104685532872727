export default [
  {
    text: "LoF",
    path: "/lof",
    icon: 'customMain'
  },
  {
    text: "Main",
    path: "/home",
    icon: 'customHome'
  },
  {
    text: 'Effort rate',
    path: '/effort-rate',
    icon: 'effortRateIco'
  },
  {
    text: 'Occupancy',
    path: '/occupancy',
    icon: 'portfolioIco'
  },
  /*{
    text: 'Occupancy',
    path: '/portfolio-navigation',
    icon: 'portfolioIco'
  },*/
  {
    text: "Footfall",
    path: "/footfall",
    icon: "footfallIco"
  },
  {
    text: 'Net sales',
    path: '/net-sales',
    icon: "netSalesIco"
  },
  {
    text: 'Rent & Svc',
    path: '/rent-services',
    icon: "turnoverIco"
  },
  {
    text: 'Tenants',
    path: '/tenants/all-tenants-page',
    icon: 'tenantIco'
  },

  //  ESEMPIO DI SOTTOMENU
  // {
  //   text: "Examples",
  //   icon: "folder",
  //   items: [
  //     {
  //       text: "Profile",
  //       path: "/profile"
  //     },
  //     {
  //       text: "Tasks",
  //       path: "/tasks"
  //     }
  //   ]
  // }, , , ,
  ];
