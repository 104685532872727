<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card" style="position: relative">

    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">

      <div class="logo" style="margin: 0 auto; padding-bottom: 102px; height: 58px; width: 451px;">
        <img src="@/assets/images/logo_LoF.png" alt="" style="width: 100%">
      </div>
      <div class="dx-card content">
        <div class="header">
          <div class="title">{{title}}</div>
          <div class="description">{{description}}</div>
        </div>
        <slot />
      </div>

    </div>


  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  },
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
.single-card {
  width: 100%;
  height: 100%;
  //background: linear-gradient(270deg, #E3B41A 95%, #FFD341 86.45%, #FFD341 62.7%);
  background: radial-gradient(84.11% 149.68% at 14.79% 17.72%, rgba(227, 180, 26, 0.95) 0%, rgba(255, 211, 65, 0.8645) 34.89%, rgba(255, 211, 65, 0.627) 97.71%);

  .dx-card {
    width: 603px !important;
    height: 404px !important;
    border-radius: 24px !important;

    //width: 330px;
    margin: auto auto;
    padding: 76px 104px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        //color: $base-text-color;
        //line-height: 28px;
        //font-weight: 500;
        //font-size: 24px;

        font-size: 28px;
        font-weight: 400;
        line-height: 38px;
        text-align: center;
        color: #015560;
      }

      .description {
        //color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}
</style>
